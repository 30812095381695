// import { useState } from "react";

const ProfileInfo = ({ emailAddress }) => {
    // const [isEmailVisible, setEmailVisible] = useState(false);

    // const toggleEmailVisibility = () => {
    //     setEmailVisible(!isEmailVisible);
    // };

    return (
        <div className="space-y-8 ml-4 mr-4 bg-gray-200 p-4 rounded-md border-2 border-primary">
            <div className="flex flex-col">
                <div className="flex items-center space-x-2 border-b pb-2">
                    <div className="rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                            <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                        </svg>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-xs text-gray-500 text-left">Email:</span>
                        {/* <div className="flex flex-col cursor-pointer select-none" onClick={toggleEmailVisibility}>
                            <div onClick={toggleEmailVisibility} className={`${isEmailVisible ? '' : 'tooltip tooltip-open sm:tooltip-top md:tooltip-right tooltip-primary mt-2 md:mt-0'}`} data-tip="Click to reveal">
                                <span className={`text-secondary-focus ${isEmailVisible ? '' : 'blur-sm animate-pulse bg-black opacity-50 rounded-full'}`}>{emailAddress}</span>
                            </div>
                        </div> */}
                        <span className={`text-secondary-focus`}>{emailAddress}</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex items-center space-x-2 border-b pb-2">
                    <div className="rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-xs text-gray-500 text-left">Location:</span>
                        <span className="text-secondary-focus">Las Vegas, NV</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex items-center space-x-2 border-b pb-2">
                    <div className="rounded-full">
                        <svg width="24px" height="24px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>Linkedin</title>
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <path d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z" id="Shape" fill="#000000"></path>
                            </g>
                        </svg>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-xs text-gray-500 text-left">LinkedIn:</span>
                        <a className="text-secondary-focus" href="http://linkedin.com/in/gordon-zhong" target="blank">Let&#39;s connect!</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileInfo;
